import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import Cards from "./Cards";

const CuadroTres = () => {

  return (
    <>
      <Container fluid className={"bgImgOne mb-5 mt-5 shadow"}>
        <Row className={"bgColorGreen"}>
          <Col>
            <h3 className={"d-flex justify-content-center mt-md-2 mt-1 mb-md-2 mb-1  fw-bold styleColorWhite"}>Asesorías Explore</h3>
          </Col>
        </Row>
        <Row className={"flex-column-reverse flex-md-row-reverse"}>
          <Col>
            <Cards
              title={"Asesoría Explore"}
              content={"Es un paquete de asesorías practicas (Opportunities) y de seguimiento (Follow Up) con duración de un mes, donde te brindamos distintos tipos de herramientas como buscadores, ofertas laborales personalizadas y/o contactos de interés, para direccionar y optimizar tu búsqueda de empleo."}
              img={"https://cdn.pixabay.com/photo/2017/08/06/18/29/woman-2594934_1280.jpg"}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CuadroTres