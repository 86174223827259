import React from "react";
import {Container, Row} from "react-bootstrap";

const Cards = (props) => {
  return (
    <>
      <Container>
        <div className="py-5">
          <Row className={"d-md-flex justify-content-md-center"}>
            <div className="col-md-12 mb-3 mb-lg-0">
              <div className="hover hover-4 text-white rounded"><img
                src={props.img} alt=""/>
                <div className="hover-overlay"></div>
                <div className="hover-4-content mt-md-3 mt-5">
                  <h4 className="hover-4-title text-uppercase font-weight-bold mb-0 title-movil">{props.title}</h4>
                  <p className="texto-movil hover-4-description text-uppercase mb-0">{props.content}</p>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default Cards;
