import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Col, Container, Row} from "react-bootstrap";
import logo from '../img/Logo 2behunted en PNG.png';


function NavOptions() {
  return (
    <Container fluid className={"mt-md-3"}>
      <Navbar bg="light" expand="lg" className={"mt-5 borderNav shadow"}>
        <Container fluid>
          <Navbar.Brand href="/"><img className="navbarLogoSize" src={logo} alt="Logo"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Row className="mx-md-auto">
              <Col md="auto">
                <Nav className="me-auto">
                  <Nav.Link className={"me-md-4 ms-md-4 fw-bold logoTextColor"} href="#aboutUs">Sobre Nosotros</Nav.Link>
                  <Nav.Link className={"me-md-4 ms-md-4 fw-bold logoTextColor"} href="#services">Nuestras Asesorías</Nav.Link>
                  <Nav.Link className={"me-md-4 ms-md-4 fw-bold logoTextColor"} href="#contactUsAlt">Contáctanos</Nav.Link>
                  <Nav.Link className={"me-md-4 ms-md-4 fw-bold logoTextColor"} href="https://2behunted.setmore.com" target={"_blank"} rel="noreferrer">Agenda con nosotros</Nav.Link>
                </Nav>
              </Col>
            </Row>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
}

export default NavOptions;