import React from 'react'
import {Col, Container, Row} from "react-bootstrap";

const Intro = () => {

  return (
    <>
      <Container fluid className={"bgImgTwo mb-5 mt-5 shadow d-none d-sm-block"} id={"intro"}>
        <Row className={"bgColorGreen"}>
          <Col>
            <h3 className={"d-flex justify-content-center mt-md-2 mt-1 mb-md-2 mb-1  fw-bold styleColorWhite"}>En 2BeHunted te empoderamos para encontrar tu próximo trabajo</h3>
          </Col>
        </Row>
        <Row>
            <div id="carouselExampleIndicators" className="carousel slide g-0" data-bs-interval="6000" data-bs-ride="carousel" data-pause="hover">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                        className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"
                        aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"
                        aria-label="Slide 5"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <svg id={"carOne"} className="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400"
                       xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: First slide"
                       preserveAspectRatio="xMidYMid slice" focusable="false">
                  </svg>
                  <div className="carousel-caption d-none d-md-block">
                    <h5>¿Buscas una manera eficiente de encontrar trabajo?</h5>
                    <p>Bienvenido a 2BeHunted, con nosotros podrás mejorar la búsqueda de tu trabajo ideal, como potenciar tus habilidades, mostrarlas y hacerlas notar.</p>
                  </div>

                </div>
                <div className="carousel-item">
                  <svg id={"carTwo"} className="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400"
                       xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Second slide"
                       preserveAspectRatio="xMidYMid slice" focusable="false">
                  </svg>
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Empoderamiento</h5>
                    <p>Nuestra asesoría no pone al cliente en una posición pasiva, sino que lo motiva a realizar acciones para encontrar el trabajo que desea.</p>
                  </div>

                </div>
                <div className="carousel-item">
                  <svg id={"carThree"} className="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400"
                       xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Third slide"
                       preserveAspectRatio="xMidYMid slice" focusable="false">
                  </svg>
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Seguimiento</h5>
                    <p>Nuestra asesoría da un acompañamiento integral en la búsqueda del empleo, ofreciéndote alternativas de trabajo respecto a tu perfil.</p>
                  </div>

                </div>
                <div className="carousel-item">
                  <svg id={"carFour"} className="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400"
                       xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Fourth slide"
                       preserveAspectRatio="xMidYMid slice" focusable="false">
                  </svg>
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Reinvención</h5>
                    <p>Nuestra asesoría te hace ver más alternativas y opciones de empleabilidad más allá de las que has experimentado, en base a tus talentos y habilidades.</p>
                  </div>

                </div>
                <div className="carousel-item">
                  <svg id={"carFive"} className="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400"
                       xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Fifth slide"
                       preserveAspectRatio="xMidYMid slice" focusable="false">
                  </svg>
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Sacamos lo mejor de ti</h5>
                    <p>Nuestra asesoría es personalizada y se enfoca en las fortalezas que te permitan descubrirte y descubrir nuevos caminos.</p>
                  </div>

                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </Row>
      </Container>
    </>
  );
};

export default Intro