import React from 'react'

const Atmbutton = (props) => {

  const handleClick = () => {
    const element = document.querySelector(props.href);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <button type={props.type} className={props.clases} onClick={handleClick}>{props.texto}</button>
  );
}

export default Atmbutton;