import React from "react";
import Atmbutton from "../components/Atmbutton";
import {Col, Container, Row} from "react-bootstrap";

const NavContact = () => {
  return (
    <Container fluid className={"bgColorGreen pt-2 pb-2 fixed-top"}>
      <Row>
        <Col className={""}>
          <div className={"d-flex justify-content-center"}>
            <div className="pe-md-2 ps-2 styleColorWhite d-none d-sm-block">
              ¡Agenda una cita gratis!
            </div>
            <a className={"text-decoration-none text-reset"} href={"https://2behunted.setmore.com"} target={"_blank"} rel={"noreferrer"}>
              <button className={"styleColorGreen bgColorWhite btn btn-primary btn-sm buttonBorderShape buttonOnHover"}>
              Agenda aquí
              </button>
            </a>
            <Atmbutton
              clases={
                "ms-3 styleColorGreen bgColorWhite btn btn-primary btn-sm buttonBorderShape buttonOnHover"
              }
              type='button'
              texto="Contáctanos"
              href={"#contactUs"}
            />

          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NavContact;