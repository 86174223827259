import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import Cards from "./Cards";

const Cuadro = () => {

  return (
    <>
      <Container fluid className={"bgImgOne mb-5 mt-5 shadow"} id={"services"}>
        <Row className={"bgColorGreen"}>
          <Col>
            <h3 className={"d-flex justify-content-center mt-md-2 mt-1 mb-md-2 mb-1  fw-bold styleColorWhite"}>Asesorías Reinvent</h3>
          </Col>
        </Row>
        <Row className={"flex-column-reverse flex-md-row-reverse"}>
          <Col>
            <Cards
              title={"Asesoría Meet You"}
              content={"Aquí identificarás tus principales habilidades laborales y áreas de oportunidad. Con ello crearemos un mapa de talentos, que será clave para dirigir tu proceso de búsqueda de empleo."}
              img={"https://cdn.pixabay.com/photo/2015/01/09/11/09/meeting-594091_1280.jpg"}
            />
          </Col>
          <Col>
            <Cards
              title={"Asesoría Focus"}
              content={"Aquí definiremos tus metas laborales, te ayudaremos a visualizar más oportunidades y generaremos un plan de acción personalizado. Será tu guía para llegar a tus objetivos de empleo más rápido y de forma sencilla."}
              img={"https://cdn.pixabay.com/photo/2017/07/06/14/48/organization-2478211_1280.jpg"}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Cuadro