import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Principal from "./components/Principal";


function App() {
  return <>
    <BrowserRouter>
      <Routes>
        <Route element={<Principal/>} path="/" />
      </Routes>
    </BrowserRouter>
  </>;
}

export default App;
