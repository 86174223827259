import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import NavOptions from "./Navbar";
import Footer from "./Footer";
import NavContact from "./NavContact";
import Cuadro from "./Cuadro";
import ContactUs from "./ContactUs";
import CuadroDos from "./CuadroDos";
import CuadroTres from "./CuadroTres";
import IntroUs from "./IntroUs";
import AboutUs from "./AboutUs";


const Principal = () => {
  return (
  <Container fluid className={"backImage"}>
    <Row>
        <NavContact/>
    </Row>
    <Row>
      <NavOptions/>
    </Row>
    <Row className={"d-none d-sm-block"}>
      <Col>
        <IntroUs/>
      </Col>
    </Row>
    <Row>
      <Col>
        <Cuadro/>
      </Col>
    </Row>
    <Row>
      <Col>
        <CuadroDos/>
      </Col>
    </Row>
    <Row>
      <Col>
        <CuadroTres/>
      </Col>
    </Row>
    <Row>
      <Col>
        <AboutUs/>
      </Col>
    </Row>
    <Row>
      <Col>
        <ContactUs/>
      </Col>
    </Row>
    <Row>
      <Col>
        <Footer/>
      </Col>
    </Row>
  </Container>
  )}

export default Principal