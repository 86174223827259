import React from "react";
import {Col, Container, Row} from "react-bootstrap";

const Footer = () => {
  return (
    <Container fluid className={"bgColorGreen fixed-bottom"}>
        <Row>
          <Col>
            <div className={"d-flex justify-content-start"}>
              <small className={"styleColorWhite"}>2 Be Hunted todos los derechos reservados</small>
            </div>
          </Col>
          <Col>
            <div className={"d-flex justify-content-end"}>
              <a
                className="styleColorWhite text-decoration-none footerLinkDisplay"
                href="https://www.linkedin.com/in/asalazard/"
                target={"_blank"}
                rel={"noreferrer"}
              >
                <small>Web Design by Alvaro</small>
              </a>
            </div>
          </Col>
        </Row>
    </Container>
  );
};

export default Footer;