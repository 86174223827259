import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import Cards from "./Cards";

const CuadroDos = () => {

  return (
    <>
      <Container fluid className={"bgImgOne mb-5 mt-5 shadow"}>

        <Row className={"bgColorGreen"}>
          <Col>
            <h3 className={"d-flex justify-content-center mt-md-2 mt-1 mb-md-2 mb-1  fw-bold styleColorWhite"}>Asesorías Apply</h3>
          </Col>
        </Row>

        <Row className={"flex-column-reverse flex-md-row-reverse"}>
          <Col>
            <Cards
              title={"Asesoría Create"}
              content={"Te apoyaremos en la mejora de tu CV, optimizándolo para los procesos actuales de selección de personas y destacando aspectos importantes que buscan los reclutadores."}
              img={"https://cdn.pixabay.com/photo/2015/07/28/21/01/create-865017_1280.jpg"}
            />
          </Col>
          <Col>
            <Cards
              title={"Asesoría Socialize"}
              content={"Te ayudaremos a realizar un mejor perfil de LinkedIn y utilizar la plataforma para encontrar nuevas oportunidades de trabajo."}
              img={"https://cdn.pixabay.com/photo/2017/11/06/08/42/personal-2923048_1280.jpg"}
            />
          </Col>
        </Row>
        <Row className={"flex-column-reverse flex-md-row-reverse"}>
          <Col>
            <Cards
              title={"Asesoría Interact"}
              content={"Te aconsejaremos sobre qué hacer, qué decir y otros aspectos importantes sobre la entrevista laboral. Seremos tus entrevistadores simulando en español y/o ingles este proceso y te daremos feedback para que hagas de la entrevista tu mejor experiencia."}
              img={"https://cdn.pixabay.com/photo/2017/07/31/11/21/people-2557396_1280.jpg"}
            />
          </Col>
          <Col>
            <Cards
              title={"Asesoría Interact online"}
              content={"Como sabemos, una entrevista de trabajo online es muy diferente a una presencial, ofrecemos una asesoría especialmente dedicada a practicar una entrevista de trabajo por videoconferencia. Te ayudaremos a manejar el lenguaje no verbal y cómo interactuar en esta modalidad. Disponible en español y/o inglés."}
              img={"https://cdn.pixabay.com/photo/2021/11/14/18/36/telework-6795505_1280.jpg"}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CuadroDos