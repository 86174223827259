import React from 'react'
import {Col, Container, Row} from "react-bootstrap";

const AboutUs = () => {

  return (
    <>
      <Container fluid className={"bgImgOne mb-5 mt-5 shadow"} id={"aboutUs"}>

        <Row className={"bgColorGreen"}>
          <Col>
            <h3 className={"d-flex justify-content-center mt-md-2 mt-1 mb-md-2 mb-1  fw-bold styleColorWhite"}>Sobre Nosotros</h3>
          </Col>
        </Row>

        <Row className={"flex-column-reverse flex-md-row-reverse"}>
          <Col className={"justify-content-center d-flex mb-3 mt-3"}>
            <div className="card shadow">
              <div className="img-bx">
                <img src={require("../img/Lucard.jpg")} alt="img"/>
              </div>
              <div className="content">
                <div className="detail">
                  <h2>Lucía Nuñez</h2>
                  <small className={"fw-bold text-black"}>Human Resources Manager, Headhunter, Coach/Recruiting</small>
                  <a href="https://www.linkedin.com/in/lucia-nuñez-heredia-30597019/" target="_blank" rel="noreferrer" ><img alt={"Lucía img"} className={"cardLinkImg mt-1"} src={"https://cdn-icons-png.flaticon.com/512/3669/3669739.png"}/></a>
                </div>
              </div>
            </div>
          </Col>
          <Col className={"justify-content-center d-flex mb-3 mt-3"}>
            <div className="card shadow">
              <div className="img-bx">
                <img src={require("../img/Ancard.jpg")} alt="img"/>
              </div>
              <div className="content">
                <div className="detail">
                  <h2>Anggy Castaño</h2>
                  <small className={"fw-bold text-black"}>IT Recruiter & Account Executive</small>
                  <a href="https://www.linkedin.com/in/anggy-castano/" target="_blank" rel="noreferrer"><img alt={"Anggy img"} className={"cardLinkImg mt-1"} src={"https://cdn-icons-png.flaticon.com/512/3669/3669739.png"}/></a>
                </div>
              </div>
            </div>
          </Col>
          <Col className={"justify-content-center d-flex mb-3 mt-3"}>
            <div className="card shadow">
              <div className="img-bx">
                <img src={require("../img/Felicard.jpg")} alt="img"/>
              </div>
              <div className="content">
                <div className="detail">
                  <h2>Felipe Bravo</h2>
                  <small className={"fw-bold text-black"}>Especialista en Consultoría, Desarrollo Organizacional y Gestión de Personas</small>
                  <a href="https://www.linkedin.com/in/felipe-bravo-duarte/" target="_blank" rel="noreferrer" ><img alt={"Felipe img"} className={"cardLinkImg mt-1"} src={"https://cdn-icons-png.flaticon.com/512/3669/3669739.png"}/></a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutUs