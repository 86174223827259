import React from "react";
import {Col, Row} from "react-bootstrap";

const ContactUs = () => {
  return(
    <>
      <Row className={"bgColorGreen"}>
        <Col>
          <h3 className={"d-flex justify-content-center mt-md-2 mt-1 mb-md-2 mb-1  fw-bold styleColorWhite"}>¡Contacta con nosotros!</h3>
        </Col>
      </Row>
      <div className={"row g-0 bgImgOne"} id={"contactUs"}>
        <div className={"col pt-5 pb-5 ps-3 pe-3"} id={"contactUsAlt"}>
          <div className={"container-fluid g-0"}>
            <form action="https://formsubmit.io/send/contacto@2behunted.com" method="POST" className={"row g-0 p-4"}>
              <div className={"col-sm-12 col-md-5"}>
                <div className={"row g-0"}>
                  <div className="form-group col-10 col-md-5 me-md-5 me-5">
                    <label className={"textColorBlack fw-bolder"}>Nombre</label>
                    <input autoComplete={"off"} name="Nombre" type="text" className="border-0 bg-dark-subtle rounded-pill
                    form-control shadow" id="nameForm" aria-describedby="nameHelp" placeholder="Ingrese nombre" required/>
                  </div>
                  <div className="form-group col-10 col-md-5">
                    <label className={"textColorBlack fw-bolder"}>Apellido</label>
                    <input autoComplete={"off"} name="Apellido" type="text" className="border-0 bg-dark-subtle rounded-pill
                    form-control shadow" id="apellidoForm" aria-describedby="apellidoHelp" placeholder="Ingrese apellido" required/>
                  </div>
                </div>
                <div className={"row g-0 mb-md-4 mb-sm-2"}>
                  <div className="form-group col-10 col-md-5 me-md-5 me-5">
                    <label className={"textColorBlack fw-bolder"}>Email</label>
                    <input autoComplete={"off"} name="Email" type="email" className="border-0 bg-dark-subtle rounded-pill
                    form-control shadow" id="emailForm" aria-describedby="emailHelp" placeholder="Ingrese email" required/>
                  </div>
                  <div className="form-group col-10 col-md-5">
                    <label className={"textColorBlack fw-bolder"}>Teléfono</label>
                    <input autoComplete={"off"} name="Teléfono" type="tel" className="border-0 bg-dark-subtle rounded-pill
                    form-control shadow" id="apellidoForm" aria-describedby="phoneHelp" placeholder="Teléfono" required/>
                  </div>
                  <div className="form-group col-11 col-md-11">
                    <label className={"textColorBlack fw-bolder"}>Ingrese su consulta</label>
                    <textarea autoComplete={"off"} name="Consulta" className="border-0 bg-dark-subtle form-control shadow" id="apellidoForm" aria-describedby="phoneHelp"
                              placeholder="Responderemos a la brevedad" rows="3" required/>
                  </div>
                </div>
                <p>
                  <small id="emailHelp" className="form-text text-muted textColorBlack">Sus datos no serán usados con otros
                    propósitos que no sean respecto al servicio de 2 Be Hunted.</small>
                </p>
                <div className={"row g-0 mt-md-2 mb-5 mb-md-0"}>
                  <div className={"col-2"}>
                    <button type="submit" className="styleColorWhite bgColorGreen btn btn-primary buttonOnHoverForm buttonBorderShape">Enviar</button>
                  </div>
                </div>
              </div>
              <div className={"col-sm-12 col-md-6"}>
                <div className={"row g-0 mb-5"}>
                  <div className={"col-12 col-md-5 d-flex justify-content-center"}>
                    <img alt={"iconsForms"} src={"https://cdn-icons-png.flaticon.com/512/1924/1924299.png"} className={"logoSize"}/>
                  </div>
                  <div className={"col-12 col-md-5 d-flex justify-content-center"}>
                    <p className={"textColorBlack fw-bolder"}>Te ayudamos a reinventarte, actualizarte y desarrollar tu potencial</p>
                  </div>
                </div>
                <div className={"row g-0 mb-5"}>
                  <div className={"col-12 col-md-5 d-flex justify-content-center"}>
                    <img alt={"iconsForms"} src={"https://cdn-icons-png.flaticon.com/512/2602/2602816.png"} className={"logoSize"}/>
                  </div>
                  <div className={"col-12 col-md-5 d-flex justify-content-center"}>
                    <p className={"textColorBlack fw-bolder"}>Te respondemos a la brevedad para poder comenzar tu reinvención</p>
                  </div>
                </div>
                <div className={"row g-0"}>
                  <div className={"col-12 col-md-5 d-flex justify-content-center"}>
                    <img alt={"iconsForms"} src={"https://cdn-icons-png.flaticon.com/512/4371/4371446.png"} className={"logoSize"}/>
                  </div>
                  <div className={"col-12 col-md-5 d-flex justify-content-center"}>
                    <p className={"textColorBlack fw-bolder"}>Te entregamos el respaldo profesional de más alto estándar</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;